/*fonts*/

@font-face {
    font-family: 'work_sansregular';
    src: url('../fonts/worksans-regular-webfont.eot');
    src: url('../fonts/worksans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/worksans-regular-webfont.ttf') format('truetype'),
         url('../fonts/worksans-regular-webfont.svg#work_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'work_sanssemibold';
    src: url('../fonts/worksans-semibold-webfont.eot');
    src: url('../fonts/worksans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/worksans-semibold-webfont.ttf') format('truetype'),
         url('../fonts/worksans-semibold-webfont.svg#work_sanssemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}

body{
	    height: 100vh;
	font-family: 'work_sansregular';  
	/* color: #939393;  */
	color: #313131;
	background: -moz-linear-gradient(53deg, rgba(20,76,160,1) 0%, rgba(239,55,62,1) 100%); /* ff3.6+ */
background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(20,76,160,1)), color-stop(100%, rgba(239,55,62,1))); /* safari4+,chrome */
background: -webkit-linear-gradient(53deg, rgba(20,76,160,1) 0%, rgba(239,55,62,1) 100%); /* safari5.1+,chrome10+ */
background: -o-linear-gradient(53deg, rgba(20,76,160,1) 0%, rgba(239,55,62,1) 100%); /* opera 11.10+ */
background: -ms-linear-gradient(53deg, rgba(20,76,160,1) 0%, rgba(239,55,62,1) 100%); /* ie10+ */
background: linear-gradient(37deg, rgba(20,76,160,1) 0%, rgba(239,55,62,1) 100%); /* w3c */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef373e', endColorstr='#144ca0',GradientType=0 ); /* ie6-9 */
}



h1, h2, h3, h4, h5, h6{
	font-family: 'work_sansregular';
}
a{
	color: #144ca0;
}


/* Menu */
.navbar{
	background: #fff; 
	padding:0px 0px 0px 10px;
	-webkit-box-shadow: -1px 4px 9px 0px rgba(63,16,16,0.3);
	-moz-box-shadow: -1px 4px 9px 0px rgba(63,16,16,0.3);
	box-shadow: -1px 4px 9px 0px rgba(63,16,16,0.3);}

.navbar-nav .nav-link{
	padding-left: 30px !important;
	padding-right: 30px !important;
	color: #939393;
}
.navbar-nav .nav-link:hover, .active{
	color: #ef373e !important;
}
.navbar-nav .nav-link img{
	margin-right: 10px;
}


.bdr-left{
	border-left:1px solid #f1f1f1 !important;
}
@media (max-width: 1024px) { 
	.app-card{
		width: 100% !important;
		margin-top:0 !important;
	}
	}
@media (max-width: 770px) { 
	.app-card{
		width: 100% !important;
		margin-top:0 !important;
	}
	ul.mini-menu li{
	border-right: 0px solid gray !important;	
	
}
	}

@media (max-width: 580px) { 
	.app-card{
		width: 100% !important;
		margin-top:0 !important;
	}
	.navbar-collapse{
	margin-top: 10px;
	border-top: 1px solid #f1f1f1 !important;
}




.navbar-nav .nav-link {
    /* padding-left: 30px !important; */
    /* padding-right: 30px !important; */
    color: #939393;
    padding: 10px 0 !important;
    margin-top: 10px;
}
.bdr-left{
	border-left: 0 !important;
	border-top:1px solid #f1f1f1;
}
.app-wrapper{
		padding: 0 !important;
		margin-top: 15px
}
.copyrights {
    margin-top: 15px;
}
 }
/*Global Styles*/
.primary-color{color: #144ca0;}
.primary-bg{background:#144ca0;}
.secondary-color{color: #ef373e;}
.secondary-bg{background: #ef373e;}

.app-wrapper{
	padding: 10px;
}
.form-wrapper{
	background: #ffffff;	-webkit-box-shadow: -1px 4px 9px 0px rgba(63,16,16,0.3);
	-moz-box-shadow: -1px 4px 9px 0px rgba(63,16,16,0.3);
	box-shadow: -1px 4px 9px 0px rgba(63,16,16,0.3);border-radius: 6px;}
.form-title{
	border-radius: 6px 6px 0 0; 
	background:#144ca0; 
	text-align: left;
	padding-left: 100px;
	position: relative;
	padding-top: 10px;
	padding-bottom: 10px;
}
.form-title-icon img{
	height: 50px;
}
.form-title-heading h1{
	color: #fff;
	font-size: 24px;
	font-weight: normal;
	margin: 0;
}
.form-title-icon{
	border-radius: 6px 0px 0 0; 
	position: absolute;
	left: 0;
	top: 0;
	background: #fff; 
	display: inline-block; 
	float: left;
 }

.form-body{ 
	padding:30px;
}
.form-footer{}
/* .form-control{
	height: 50px;
} */
.primary-btn{
	cursor: pointer;
	padding:12px 20px;
	border:0;
	color: #fff;
    display: inline-block;
    text-align: center;
     background-color: #144BA0;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.primary-btn:hover{
	background: #092d64;
}

.secondary-btn{
	cursor: pointer;
	padding:12px 20px;
	border:0;
	color: #fff;
    display: inline-block;
    text-align: center;
     background-color: #ef373e;
	border-radius: .25rem;
	margin: 2px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.secondary-btn:hover{
	background: #c91a21;
	color: #fff;
	text-decoration: none;
}

.copyrights{
	width: 100%;
	 text-align: center;
	 color: #fff;
}


.custom-table h4{
	margin-bottom: 20px;
}

.custom-table .table .thead-dark th {
    color: #fff;
    background-color: #144ca0;
    border-color: #144ca0 !important;
    font-family: 'work_sansregular';
    font-weight: normal;
    box-shadow: none;
}
.app-card{
	margin:0 auto;
	margin-top: 100px;
	background: #fff;
	
	padding: 30px;
	width: 30%;
	-webkit-box-shadow: -1px 4px 9px 0px rgba(227,227,227,1);
		-moz-box-shadow: -1px 4px 9px 0px rgba(227,227,227,1);
	box-shadow: -1px 4px 9px 0px rgba(227,227,227,1);border-radius: 6px;}

.app-card h4{
	margin: 30px 0 !important;
}

.login-style a{
	display: inline-block;
	margin: 20px 0;
	color:#144ca0;
}

.pad-t-b-10{
	padding: 10px 0;
}

.tab-content>.tab-pane {
    padding: 30px;
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    /* color: #939393 !important; */
	color: #313131 !important;
    background: #f8feff;
}

.full-width{
	width: 100%;
}

ul.mini-menu {
list-style: none;
padding:0;
border-radius: 5px;
margin-top: 20px;

}
ul.mini-menu li{
	
	padding: 15px;
	border-bottom: 5px solid #f2f2f2;
	/* border-bottom: 1px solid #ff6a70; */
	
}
ul.mini-menu li:hover{
}


ul.mini-menu li a{
	color: #ffc7c9;
}
ul.mini-menu li a:hover{
	text-decoration: none;
	color: #fff;
}
ul.mini-menu li:nth-last-child(1){
	border-bottom: 0px solid #ff6a70 !important;
}


.gray-bg{
	background: #f2f2f2;
	border-radius: 5px;
	padding: 30px;
	margin-bottom: 30px;
}
hr.style13 {
	height: 10px;
	border: 0;
	box-shadow: 0 10px 10px -10px #8c8b8b inset;
}

.page-link{
	color:#939393 !important; 
}

.mar-right-20{
	margin-right: 20px;
}

.dropdown-menu {   
    top: 135%;
    border-radius: 0;
	right: 0;
    left: auto;
}

.menu-hr{
	margin: 5px 0;
}
.dropdown-item{
	color: #939393;
}
.dropdown-item:focus, .dropdown-item:hover{
	background: none;
	color: #144ca0;
}	

.mar-bot-15{
	margin-bottom: 15px;
}
.form-control{

	border: px solid #ced4da;
	-webkit-box-shadow: inset 9px 9px 5px -8px rgba(2,98,128,0.26);
-moz-box-shadow: inset 9px 9px 5px -8px rgba(2,98,128,0.26);
box-shadow: inset 9px 9px 5px -8px rgba(2,98,128,0.26);
height: calc(2.0rem);
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #f8feff;
 
}